import { Link } from "react-router-dom";

function Notfound() {
  return (
    <>
      <section>
        Page Not Found
      </section>
      
    </>
  );
}

export default Notfound;
