import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import carousel1 from "../images/carousel/01.jpg";
import carousel2 from "../images/carousel/02.jpg";
import carousel3 from "../images/carousel/03.jpg";
import carousel4 from "../images/carousel/04.jpg";
// import carousel5 from "../images/carousel/05.jpg";
// import carousel6 from "../images/carousel/06.jpg"; 


function Product() {


  const data = [
    {id:1, img:carousel1, tag:'Tags',heading:'Heading',desc:'Description'},
    {id:2, img:carousel2, tag:'Tags',heading:'Heading',desc:'Description'},
    {id:3, img:carousel3, tag:'Tags',heading:'Heading',desc:`Description`},
    {id:4, img:carousel4, tag:'Tags',heading:'Heading',desc:`Description`},
    // {id:5, img:carousel5, tag:'Tags',heading:'Heading',desc:'Description'},
    // {id:5, img:carousel6, tag:'Tags',heading:'Heading',desc:'Description'},
    
  ]
  
  
  
  
  return (
    <>

      <section className="product-section" id="product-sec">
        <div className="container">
          <div className="product-sec">
            <h4>Gallery</h4>
            <h2>The Art Of Coffee making</h2>
            <Carousel
              additionalTransfrom={0}
              arrows ={false}
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              partialVisible={true}

              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  partialVisibilityGutter: 70
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 40
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 465
                  },
                  items: 2,
                  partialVisibilityGutter: 40
                }
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
            {
                data.map((prod) => 
                  <>
                    <div className="prod_box" >
                    <img  src={prod.img} alt={prod.heading} draggable={false}></img>
                    {/* <div class="tagd"><span className="tag">{prod.tag}</span></div> */}
                    {/* <p className="head">{prod.heading}</p>
                    <p className="desc">{prod.desc}</p> */}
                    </div>  
                  </>
                )
            }
            </Carousel>
          </div>    
        </div>
      </section>
    </>
  );
}

export default Product;
