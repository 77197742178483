import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";

function PlanTrip() {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>What we do</h3>
              <h2>Salco Trade Inc provides extensive services to</h2>
              <p>local farmers to mitigate climate impact on coffee production, such as adaptation measures workshops to smallholder coffee farmers through partnership with local civil society organizations.
              </p>
            </div>

            {/* <i class="fa-solid fa-utensils"></i>
            <i class="fa-regular fa-hand-pointer"></i>
            <i class="fa-solid fa-truck-fast"></i> */}

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
              
                <img src={SelectCar} alt="icon_img" />
                <h3>Coffee Estate</h3>
                <p>
                Established Coffee Estate 

                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Wholesale</h3>
                <p>
                Wholesale of green coffee

                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>24hr customers services</h3>
                <p>
                Established 24hr customers services center with best team in place to receive customers feedback such as roasters comments, and logistic issues.

                </p>
              </div>



              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Trade</h3>
                <p>
                We trade direct and indirect with reliable sources in various regions.

                </p>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
