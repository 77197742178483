import bannerCar from "../images/bg-image/bg-image.jpg";

function Banner() {
  return (
    <>
    <section id="home" className="banner-section">
      <div className="">
        <div className="banner-content">
          
          
          {/* img */}
          <div className="banner-content_img">
            <img
            loading="lazy"
              src={bannerCar}
              alt="banner-img"
              
            />
          </div>



          
          <div className="banner-content__text">
          <h2>
              1×20’

              </h2>
              
              <p>
              19,175 MT equivalent to <span>325 jute bags</span> with <span>59 kg</span> each.

              </p>

              <p>
              Minimum Order:<span>20 feet container</span>

              </p>

            <div className="banner-content__text__btns">
     
            </div>
          </div>

          
        </div>
      </div>


    </section>
  </>
  );
}

export default Banner;
