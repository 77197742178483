import SelectCar from "../images/stats/01.png";
import Contact from "../images/stats/02.png";
import Drive from "../images/stats/03.png";

function stats() {
  return (
    <>
      <section className="stats-section">
        <div className="container">
          <div className="stats-container">
            <div className="stats-container__title">
            </div>

            {/* <i class="fa-solid fa-utensils"></i>
            <i class="fa-regular fa-hand-pointer"></i>
            <i class="fa-solid fa-truck-fast"></i> */}

            <div className="stats-container__boxes">
              {/* <div className="stats-container__boxes__box">
              
                <img src={SelectCar} alt="icon_img" />
                <h3>Coffee Estate</h3>
                <p>
                Established Coffee Estate 

                </p>
              </div> */}

              <div className="stats-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>+10 Years</h3>
                <p>
                of experience
                </p>
              </div>

              <div className="stats-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>+20 countries</h3>
                <p>
                exported
                </p>
              </div>



              <div className="stats-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>+30 Clients  </h3>
                <p>
                    all over the world
                </p>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default stats;
